<template>
  <div class="align-items-center">
    <button
      v-on:click="loadData"
      class="badge"
      :class="{
        'successful text-white': modelValue,
        'failed text-white': !modelValue,
      }"
    >
      <BaseIcon name="shield-check" v-if="modelValue" />
      <BaseIcon name="shield-x" v-else />
    </button>
    <MaterialStockModal :id="extraRow" ref="stockModal" />
  </div>
</template>
<script>
import BaseIcon from "@/components/icons/BaseIcon";
import MaterialStockModal from "../../modals/MaterialStockModal.vue";

export default {
  name: "HasAllMaterial",
  components: { MaterialStockModal, BaseIcon },
  props: {
    id: Number,
    row: Object,
    modelValue: Boolean,
    extraRow: String,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  methods: {
    loadData() {
      this.$refs.stockModal.showModal();
    },
  },
};
</script>
