<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Inventory</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`orderChange.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <DynamicTable
            v-model:rows="rows"
            v-model:items="items"
            :fix-height="true"
            :show-order="false"
            :show-pin="false"
            type="products"
          />
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import DynamicTable from "../lists/DynamicTable";
import { useStore } from "vuex";
const bootstrap = require("bootstrap");

export default {
  name: "MaterialStockModal",
  props: {
    id: String,
  },
  data: function () {
    return {
      store: useStore(),
      loadAddBtn: false,
      rows: [
        {
          name: "Image",
          key: "image",
          type: "image",
          show: true,
          editable: false,
        },
        {
          name: "Serial",
          key: "serial",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "SKU",
          key: "sku",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Stock",
          key: "total_quantity",
          type: "number",
          show: true,
          editable: false,
        },
      ],
      items: [],
      myModal: undefined,
    };
  },
  computed: {
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
  },
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
      this.load();
    },
    load() {
      http.fetch("/products/ingredient-stocks/" + this.id).then((data) => {
        this.items = [];
        for (const row of data.items) {
          row.image =
            row.image !== null ? this.mediaServer + "/" + row.image : null;
          this.items.push(row);
        }
      });
    },
  },
  components: { DynamicTable },
};
</script>
